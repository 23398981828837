<section class="content-wrapper content-padding">
    <div class="row">
        <!-- Active -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Ativos
                    </div>
                    <div class="custom-card-value">
                        {{summaries?.ATV}}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-clipboard"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- In Production -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Em Fabricação
                    </div>
                    <div class="custom-card-value">
                        {{summaries?.FBT}}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-cubes"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Awaiting Pickup -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Aguardando Coleta
                    </div>
                    <div class="custom-card-value">
                        {{summaries?.APU}}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-clock"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- On route -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Em Transporte
                    </div>
                    <div class="custom-card-value">
                        {{summaries?.TPT}}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-shipping-fast"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Delivered -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Entregues
                    </div>
                    <div class="custom-card-value">
                        {{summaries?.DLD}}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-parachute-box"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Total -->
        <div class="col-md-2">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Total
                    </div>
                    <div class="custom-card-value">
                        {{ summaries!.ATV + summaries!.FBT + summaries!.APU + summaries!.TPT + summaries!.DLD }}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-arrow-up"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" *ngFor="let currentOrder of orderArray">
        <div class="col-md-12">
            <div class="card">
                <!-- Header row -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="order-wrapper">
                            <div class="order-info">
                                <div class="order-title">Pedido:</div>
                                <div class="order-content">
                                    <div class="content-date">
                                        {{currentOrder.createdAt | dates }}
                                    </div>
                                    <div class="content-spacer"> - </div>
                                    <div class="content-id">
                                        {{currentOrder.externalId}}
                                    </div>
                                </div>
                            </div>
                            <div class="order-status">
                                <div class="title">
                                    Status:
                                </div>
                                <div class="content" [ngClass]="{ 'temporary-order': currentOrder.orderStatus?.code === 'order.status.temporary', 'active-order': currentOrder.orderStatus?.code === 'order.status.active'}">
                                    {{currentOrder.orderStatus?.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="bar"></div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-md-6">
                        <div class="row h-100">
                            <div *ngFor="let currentProduct of currentOrder.productsList" class="col-md-12" style="display: flex;">
                                <div class="product-wrapper">
                                    <div class="fotons-wrapper">
                                        <div class="fotons-item"><img [src]="currentProduct.img1?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img2?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img3?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img4?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img5?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img6?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img7?.data" alt="" srcset=""></div>
                                        <div class="fotons-item"><img [src]="currentProduct.img8?.data" alt="" srcset=""></div>
                                        <div    
                                            class="fotons-item download-all" 
                                            [ngClass]="{ forbidden:  currentOrder.orderStatus?.code === 'order.status.temporary'}" 
                                            (click)="clickDownloadAll(currentOrder.externalId!, currentProduct, !(currentOrder.orderStatus?.code === 'order.status.temporary'))"
                                        >
                                            <i class="nav-icon fa fa-download"></i>
                                        </div>
                                        
                                    </div>
                                    <div class="fotons-text">
                                        <div class="amount">
                                            <div class="amount-title">
                                                Quantidade:
                                            </div>
                                            <div class="amount-description">{{currentProduct.amount}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row h-100">
                            <div class="col-md-12 flex-center">
                                <div class="order-general-info-wrapper">
                                    <div class="order-address" *ngIf="currentOrder.deliveryAddress">
                                        <div class="cep">
                                            55555-555
                                        </div>
                                        <div class="street">
                                            Rua dos Alfeneiros
                                        </div>
                                        <div class="number">
                                            5
                                        </div>
                                    </div>
                                    <div class="order-owner">
                                        <elmtr-user-card [user]="currentOrder.owner" [allowLinks]="!(currentOrder.orderStatus?.code === 'order.status.temporary')"></elmtr-user-card>
                                    </div>
                                    <div class="order-buttons">
                                        <div 
                                            class="button-item"  
                                            *ngIf="currentOrder.orderStatus?.code === 'order.status.active'" 
                                            (click)="clickStatus(currentOrder, 'order.status.fabricating')"
                                        >
                                            <i class="nav-icon fa fa-cubes"></i>
                                        </div>
                                        <div 
                                            class="button-item"  
                                            *ngIf="currentOrder.orderStatus?.code === 'order.status.fabricating'" 
                                            (click)="clickStatus(currentOrder, 'order.status.awaitngPickup')"
                                        >
                                            <i class="nav-icon fa fa-clock"></i>
                                        </div>
                                        <div 
                                            [ngClass]="{ forbidden: currentOrder.orderStatus?.code === 'order.status.temporary'}"
                                            class="button-item" 
                                            (click)="clickRedirect('order-details', currentOrder)"  
                                        >
                                            <i class="nav-icon fa fa-receipt"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>