export class Event {
    type: string;
    action?: string | undefined | null;
    assets?: object | undefined | null;

    constructor(type:string, assets: object | undefined | null = null, action = null){
        this.type = type;
        this.action = action;
        this.assets = assets;
    }
}

