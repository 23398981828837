import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';
import { ProductDetailsService } from './product-details.service';

import { Datatable, DatatableSettingsItem, DatatableSettingsItemType } from '../../elementar-common/models';
import { InquiryResponse, Product, ProductsOrder, ResponseMessage } from '../../../app/models';
import { Response } from '../../../app/models/Response';


@Component({
  selector: 'elmtr-product-details',
  templateUrl: './product-details.component.html',
  styleUrl: './product-details.component.scss'
})
export class ProductDetailsComponent implements OnInit {
  currentProduct?: Product;
  liveOrdersAmount?: number;
  productsOrderData?: any[];
  

  datatable = new Datatable([
    new DatatableSettingsItem('img1',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img2',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img3',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img4',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img5',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img6',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img7',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('img8',DatatableSettingsItemType.Image, ''),
    new DatatableSettingsItem('client',DatatableSettingsItemType.Text, 'Cliente'),
    new DatatableSettingsItem('orderExtId',DatatableSettingsItemType.TextCustom, 'Id Pedido'),
    new DatatableSettingsItem('orderStatusName',DatatableSettingsItemType.TextCustom, 'Status'),
  ]);

  constructor(private router: Router, private productDetailsService: ProductDetailsService, private toastrService: ToastrService){}

  ngOnInit(){
    // console.log('this.router', `${splittedRoute[2]}.${splittedRoute[3]}`);
    const splittedRoute = this.router.url.split('/');

    this.productDetailsService.fetchProductByCode(`${splittedRoute[2]}.${splittedRoute[3]}`)
      .subscribe((productResponse: Response<Product>) => {
        // console.log('this.productDetailsService.fetchProductByCode() => productResponse', productResponse);
        if (productResponse.isInError || !productResponse.operationSuccess) {
          productResponse.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message);
          })
          return;
        }
        this.currentProduct = productResponse.item;
      })

    this.productDetailsService.fetchLiveOrdersByProductCode(`${splittedRoute[2]}.${splittedRoute[3]}`)
      .subscribe((orderResponse: InquiryResponse<Product>) => {
        // console.log('this.productDetailsService.getProductByCode() => orderResponse', orderResponse);
        if (orderResponse.isInError || !orderResponse.operationSuccess) {
          orderResponse.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message);
          })
          return;
        }
        this.liveOrdersAmount = orderResponse.totalItems;
      })
    
    this.productDetailsService.fetchProductsOnOrdersByCode(`${splittedRoute[2]}.${splittedRoute[3]}`)
      .subscribe((productsOrderResponse: InquiryResponse<ProductsOrder>) => {
        // console.log('this.productDetailsService.getProductByCode() => orderResponse', orderResponse);
        if (productsOrderResponse.isInError || !productsOrderResponse.operationSuccess) {
          productsOrderResponse.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message);
          })
          return;
        }
        this.productsOrderData = this.prepareData(productsOrderResponse.returnValues!);
      })
    // activatedRoute
  }

  prepareData(toBePrepared: ProductsOrder[]): any[] {
    return toBePrepared.map((currentProductOrder: ProductsOrder) => {
      return {
        // ...currentProductOrder,
        id: currentProductOrder.id,
        img1: currentProductOrder.img1,
        img2: currentProductOrder.img2,
        img3: currentProductOrder.img3,
        img4: currentProductOrder.img4,
        img5: currentProductOrder.img5,
        img6: currentProductOrder.img6,
        img7: currentProductOrder.img7,
        img8: currentProductOrder.img8,
        orderExtId: { 
          text: currentProductOrder.order?.externalId, 
          size: 7
        },
        orderStatusName: { 
          text: currentProductOrder.order?.orderStatus?.name, 
          ngStyle: {
            color: currentProductOrder.order?.orderStatus?.code === 'order.status.active' ? 
              'green' : 
              currentProductOrder.order?.orderStatus?.code === 'order.status.temporary' ? 
                'blue' : 
                null
          }},
        client:  currentProductOrder.order?.owner?.firstname
      };
    });
  }
}