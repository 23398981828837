export class SearchbarSettings {
  columns: Array<SearchbarColumn>;

  constructor(columns = [ new SearchbarColumn({})] ){
    this.columns = columns;
  }
}


export class SearchbarColumn {
  classes?: Array<string> | null | undefined;
  styles?: Array<{property: string, value: any }> | null | undefined;
  columns?: Array<SearchbarColumn> | null | undefined;
  item?: SearchbarItem | null | undefined;

  columnClass: string | null = null;
  columnStyle: object | null = null;

  constructor({
    classes = ['default'],
    styles = null,
    columns = null,
    item = null,
  }){
    this.styles = styles;

    // If no item or column sent, default for inputText
    if (!columns || !item) {
      this.item = new SearchbarItem({});
    } else {
      this.columns = columns;
      this.item = item;
    }

    this.columnClass = this.processClasses(classes);
    this.columnStyle = this.processStyles(styles);
  }

  processClasses(classes: Array<string>): string {
    let classString = ``;
    classes.forEach((cssClass, index) => {
      if (index + 1 === classes.length) {
        classString = `${classString}${cssClass}`;
      } else {
        classString = `${classString}${cssClass} `;
      }
    });
    return classString;
  }

  processStyles(styles: Array<{property: string, value: any }> | null): object {
    return {};
  }
}

export class SearchbarItem {
  type: SearchbarType;
  label?: string;
  fieldName?: string;

  constructor({
    type = SearchbarType.InputText,
    label = 'Buscar',
    fieldName = 'searchbarInputText'
  }) {
    this.type = type;
    this.label = label;
    this.fieldName = fieldName;
  }
}


export enum SearchbarType {
  InputText = 'inputText',                  // Implemented
  InputEmail = 'inputEmail',                // Not Implemented
  InputPassword = 'inputPassword',          // Not Implemented
  MultiselectFilter = 'multiselectFilter',  // Not Implemented
  DropdownSelect = 'dropdownSelect',        // Not Implemented
  Checkbox = 'checkbox'

}
