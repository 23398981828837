import { Component, OnInit } from '@angular/core';
import { ClientsService } from './clients.service';
import { InquiryResponse, ResponseMessage, User } from '../../../app/models';
import { ToastrService } from 'ngx-toastr';
import { Datatable } from '../../elementar-common/models/componentModels/datatable/Datatable';
import { DatatableSettingsItem } from '../../elementar-common/models/componentModels/datatable/DatatableSettingsItem';
import { DatatableSettingsItemType } from '../../elementar-common/models/componentModels/datatable/DatatableSettingsItemType';

@Component({
  selector: 'elmtr-clients',
  templateUrl: './clients.component.html',
  styleUrl: './clients.component.scss'
})
export class ClientsComponent implements OnInit {

  datatable = new Datatable([
    new DatatableSettingsItem('fullname', DatatableSettingsItemType.Text, 'Nome'),
    new DatatableSettingsItem('email', DatatableSettingsItemType.Text, 'Email'),
    new DatatableSettingsItem('createdAt', DatatableSettingsItemType.Date, 'Criado'),
    new DatatableSettingsItem('email', DatatableSettingsItemType.Email, ''),
    new DatatableSettingsItem('details', DatatableSettingsItemType.Icon, '')
  ]);

  tableData: any[] | null = null;

  constructor(private clientsService: ClientsService, private toastrService: ToastrService){}

  ngOnInit(){
    this.clientsService.getClients()
      .subscribe((fetchClientsResult: InquiryResponse<User>) => {
        // console.log('fetchClientsResult', fetchClientsResult);
        if (fetchClientsResult.isInError || !fetchClientsResult.operationSuccess) {
          fetchClientsResult.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message);
          })
          return;
        }

        this.tableData = this.processData(fetchClientsResult.returnValues!);
        // console.log('this.tableData', this.tableData );
        
        return;
      })
  }

  /**
   * Description
   * @param {any} currentData:any[]
   * @returns {any}
   */
  processData(currentData: any[]): any[] {
    return currentData.map(element => {
      return {
        ...element, 
        fullname: `${element.firstname} ${element.lastname}`,
        details: {
          icon: 'fa-receipt',
          ngStyle: {
            cursor: 'pointer'
          }
        }
      }
    });
  }

  /**
   * Description
   * @param {any} event:any
   * @returns {any}
   */
  tableClickResponse(event: any): void {
    console.log('event', event);
    
  }

  
}
