import { Component, OnInit } from '@angular/core';

import { environment } from "../../../environments/environment";
import { Router } from '@angular/router';

@Component({
  selector: 'elmtr-header',
  // standalone: true,
  // imports: [],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  userName: string | undefined | null;
  userImage: string | undefined | null;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.userName = localStorage.getItem(environment.localStorage.user.firstname) ? localStorage.getItem(environment.localStorage.user.firstname) : 'Usuário';
    this.userImage = localStorage.getItem(environment.localStorage.user.picture);
    console.log('this.userImage', this.userImage);
    
    // this.userImage = localStorage.getItem(environment.localStorage.user.picture) ? localStorage.getItem(environment.localStorage.user.picture) : '../../../assets/images/user-placeholder.png';

  }

  signOut(): void {

    // [TODO] change the place to save this data below make a auth service
    localStorage.removeItem(environment.localStorage.user.token);
    localStorage.removeItem(environment.localStorage.user.roles);
    localStorage.removeItem(environment.localStorage.user.picture);
    localStorage.removeItem(environment.localStorage.user.firstname);
    localStorage.removeItem(environment.localStorage.user.lastname);
    localStorage.removeItem(environment.localStorage.user.email);

    this.router.navigateByUrl('/');
  }

}
