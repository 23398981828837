import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

import { InquiryResponse, User } from '../../../app/models';
@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  constructor(private httpClient: HttpClient){}

  getClients(): Observable<InquiryResponse<User>>{
    return this.httpClient.get<InquiryResponse<User>>(`${environment.apiUrl}user/clients`);
  }
}
