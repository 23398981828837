<div class="user-card-wrapper">
    <div class="info-wrapper">
        <div class="name">
            <div class="firstname">
                {{ user?.firstname}}
            </div>
            <div class="lastname">
                {{ user?.lastname}}
            </div>
        </div>
        <div class="email">
            {{ user?.email }}
        </div>
    </div>
    <div class="icons-wrapper" [ngClass]="{forbidden: !allowLinks, followLink: allowLinks}">
        <div class="user-details" (click)="userCardClick(allowLinks, 'info')">
            <i class="nav-icon fa fa-info"></i>
        </div>
        <div class="message" (click)="userCardClick(allowLinks, 'message')">
            <i class="nav-icon fa fa-envelope"></i>
        </div>
    </div>
</div>