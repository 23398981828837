<aside class="main-sidebar elevation-4 flex flex-column custom-sidebar">
  <a class="brand-link">
      <img id="logo-brand" width="100%" [src]="'../../../assets/images/fottons_branco.png'" alt="Fotons">
      <!-- <div class="brand-wrapper">
        <div class="brand-item">
          <div class="brand-logo-wrapper">
            <img src="../../../assets/images/NO_BG_BANNER_LOGO_LIGHT.png" alt="" class="brand-logo">
          </div>
        </div>
      </div> -->
  </a>
  <div class="sidebar">
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Panel -->
          <li class="nav-item has-treeview">
            <a href="/auth" class="nav-link">
              <i class="nav-icon fas fa-window-maximize"></i>
              <p>
                Painel
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- Home -->
          <li class="nav-item has-treeview">
            <a href="/auth" class="nav-link">
              <i class="nav-icon fas fa-window-maximize"></i>
              <p>
                (c) Início
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- Fotons -->
          <li class="nav-item has-treeview">
            <a href="/auth/products/fotons" class="nav-link">
              <i class="nav-icon fas fa-camera"></i>
              <p>
                Fotons
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- Orders -->
          <li class="nav-item has-treeview">
            <a href="/auth/orders" class="nav-link">
              <i class="nav-icon fas fa-clipboard"></i>
              <p>
                Pedidos
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- My Info -->
          <li class="nav-item has-treeview">
            <a href="/auth/orders" class="nav-link">
              <i class="nav-icon fas fa-clipboard"></i>
              <p>
                (c) Meus Dados
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- My Orders -->
          <li class="nav-item has-treeview">
            <a href="/auth/orders" class="nav-link">
              <i class="nav-icon fas fa-clipboard"></i>
              <p>
                (c) Meus Pedidos
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- My Protocols -->
          <!-- <li class="nav-item has-treeview">
            <a href="/auth/orders" class="nav-link">
              <i class="nav-icon fas fa-clipboard"></i>
              <p>
                (c) Meus Protocolos
              </p>
            </a>
          </li> -->

          <!-- Clients -->
          <li class="nav-item has-treeview">
            <a href="/auth/clients" class="nav-link">
              <i class="nav-icon fas fa-users"></i>
              <p>
                Clientes
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

          <!-- Settings -->
          <li class="nav-item has-treeview">
            <a href="" class="nav-link">
              <i class="nav-icon fas fa-cogs"></i>
              <p>
                Cofigurações
                <!-- <i class="right fas fa-angle-left"></i> -->
              </p>
            </a>
          </li>

         <!-- Fotons -->
         <li class="nav-item has-treeview">
          <a href="" class="nav-link">
            <i class="nav-icon fas fa-exclamation-triangle"></i>
            <p>
              Reportar um problema
              <!-- <i class="right fas fa-angle-left"></i> -->
            </p>
          </a>
        </li>
      </ul>
    </nav> 
  </div>
  <!-- Branding content -->
  <div class="brand-wrapper">
    <div class="brand-item">
      <div class="brand-logo-wrapper">
        <img [src]="rotatorCurrentItem?.brandLogo" [alt]="rotatorCurrentItem?.brandName" class="brand-logo">
      </div>
    </div>
  </div>
</aside>
