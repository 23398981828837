
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { routes } from "./app.routes";

import { AppComponent } from "./app.component";
import { ToastrModule } from 'ngx-toastr';

import { ElementarCommonModule } from "../modules/elementar-common/elementar-common.module";
import { PublicModule } from "../modules/public/public.module";

import { FotonsModule } from "../modules/fotons/fotons.module";

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';
// registerlocaledata
registerLocaleData(ptBr);



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    ElementarCommonModule,
    FotonsModule,
    PublicModule,
    RouterModule.forRoot(routes),
    RouterOutlet,
    ToastrModule.forRoot(),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    // *************************************************
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
  //   {
  //     multi: true,
  //     provide: HTTP_INTERCEPTORS,
  //     useClass: AuthInterceptor,
  //   }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
