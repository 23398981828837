import { DatatableSettingsItemType } from "./DatatableSettingsItemType";
export class DatatableSettingsItem {
    type?: DatatableSettingsItemType = DatatableSettingsItemType.Text;
    field: string;

    label?: string | null = null;
    action?: string | null;
    icon?:string | null = null;
    // sorting?: object;

    constructor(field: string, type: DatatableSettingsItemType, label: string, opitionalItems = null){
        this.field = field;
        this.type = type;
        this.label = label;
        // console.log(label);

        if (opitionalItems) {
            this.action = opitionalItems['action'];
            this.icon = opitionalItems['icon'];
        } else {
            this.action = this.icon = null;
        }

    }
  }


