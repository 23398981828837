import { Component } from '@angular/core';

@Component({
  selector: 'elmtr-products',
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss'
})
export class ProductsComponent {

}
