import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'elmtr-public',
  templateUrl: './public.component.html',
  styleUrl: './public.component.scss'
})
export class PublicComponent implements OnInit {

  ngOnInit(): void {
    console.log('AuthenticatedComponent');
  }
}
