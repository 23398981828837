import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';

export const loggedGuard: CanActivateFn = (route, state) => {
  const token = localStorage.getItem(environment.localStorage.user.token);
  const roles = localStorage.getItem(environment.localStorage.user.roles);
  const picture = localStorage.getItem(environment.localStorage.user.picture);
  const firstname = localStorage.getItem(environment.localStorage.user.firstname);
  const lastname = localStorage.getItem(environment.localStorage.user.lastname);
  const email = localStorage.getItem(environment.localStorage.user.email);


  if (!token || !roles  || !firstname || !lastname || !email) {
    inject(ToastrService).info('Informações de login incompletas ou inexistentes, favor entrar.')
    inject(Router).navigateByUrl('/login');
  }
  
  return true;
};
