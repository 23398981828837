import { Component, Input } from '@angular/core';
import { User } from '../../../app/models/User';
import { Router } from '@angular/router';


@Component({
  selector: 'elmtr-user-card',
  templateUrl: './user-card.component.html',
  styleUrl: './user-card.component.scss'
})
export class UserCardComponent {
  @Input() user?: User;
  @Input() allowLinks: boolean = true;

  constructor(private router: Router){}
  
  userCardClick(allow: boolean = true, where: 'message' | 'info'): void {
    if (!allow) {
      return;
    }
    
    if (where === 'message') {
      
      window.open(`mailto:${this.user?.email}`);
      // ?subject=sub&body=this is body
    }

    if (where === 'info') {
      this.router.navigateByUrl(`/auth/clients/details/${this.user?.id}`);
    }
    
  }
}
