
<!-- Main content -->
<section class="content-wrapper content-padding">
    <div class="row">
        <!-- Price -->
        <div class="col-md-3">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Preço
                    </div>
                    <div class="custom-card-value">
                        {{ currentProduct?.value | currency: 'BRL' }}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-money-bill"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Active Orders -->
        <div class="col-md-3">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Pedidos em Andamento
                    </div>
                    <div class="custom-card-value">
                        {{ liveOrdersAmount }}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-clipboard"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Total Sold -->
        <div class="col-md-3">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Total Vendido
                    </div>
                    <div class="custom-card-value">
                        {{ currentProduct?.totalSoldValue| currency: 'BRL' }}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-dollar-sign"></i>
                    </div>
                </div>
            </div>
        </div>
        <!-- Total Sales -->
        <div class="col-md-3">
            <div class="card">
                <div class="custom-card-wrapper">
                    <div class="custom-card-title">
                        Contagem Vendas
                    </div>
                    <div class="custom-card-value">
                        {{ currentProduct?.totalSoldCount }}
                    </div>
                    <div class="custom-card-icon">
                        <i class="nav-icon fa fa-arrow-up"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="card">
                <div class="custom-card-wrapper custom-info-card">
                    <div class="title-name">
                        <div class="content">{{ currentProduct?.name }}</div>
                        <div class="edit-icon">
                            <i class="far fa-edit"></i>
                        </div>
                    </div>
                    <div class="title-description">
                        {{ currentProduct?.description ? currentProduct?.description : 'Nenhuma descrição' }}
                    </div>
                    <div class="info-items-wrapper">
                        <div class="created">
                            <div class="title">
                                Criado
                            </div>
                            <div class="user-card">
                                <!-- <div class="user-image">
                                    <img [src]="'../../../assets/images/user-placeholder.png'" [alt]="'Lorem'" class="mr-3 img-circle">
                                </div> -->
                                <div class="user-content-wrapper">
                                    <div class="name">
                                        {{ currentProduct?.createdBy?.firstname + ' ' + currentProduct?.createdBy?.lastname }}
                                    </div>
                                    <div class="extra-info">
                                        {{ currentProduct?.createdAt | dates }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="updated">
                            <div class="title">
                                Atualizado
                            </div>
                            <div class="user-card">
                                <!-- <div class="user-image">
                                    <img [src]="'../../../assets/images/user-placeholder.png'" [alt]="'Lorem'" class="mr-3 img-circle">
                                </div> -->
                                <div class="user-content-wrapper">
                                    <div class="name">
                                        {{ currentProduct?.updatedBy?.firstname + ' ' + currentProduct?.updatedBy?.lastname }}
                                    </div>
                                    <div class="extra-info">
                                        {{ currentProduct?.updatedAt | dates }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
        <div class="col-md-8">
            <div class="card">
                <div class="title">
                    Fotons em Andamento
                </div>
                <div class="custom-card-wrapper custom-list-card">
                    <elmtr-datatable [settings]="datatable" [data]="productsOrderData!"></elmtr-datatable>
                </div>
            </div>
        </div>
    </div>
    <!-- <p>product-details works!</p> -->
    <!-- <elmtr-datatable [settings]="datatable"></elmtr-datatable> -->
</section>
