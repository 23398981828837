import { Pipe, PipeTransform } from '@angular/core';
import { ElementarDate } from '../../../app/models';

@Pipe({
  name: 'dates'
})
export class DatesPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    // console.log('args', args);
    
    if (args.length === 0) {
      return new ElementarDate((value as ElementarDate).number).getFormatted();
    }
    
    return new ElementarDate((value as ElementarDate).number).getFormatted();
  }

}
