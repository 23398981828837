import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { OrdersService } from './orders.service';

import { InquiryResponse, OrderStatus, ResponseMessage } from '../../../app/models';
import { Response } from '../../../app/models';
import { Order } from '../../../app/models/Order';
import { ProductsOrder } from '../../../app/models/ProductsOrder';
import { User } from '../../../app/models/User';
import { OrdersSummary } from '../../../interactionModels/OrdersSummary';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'elmtr-orders',
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss'
})
export class OrdersComponent {
  constructor(private ordersService: OrdersService, private router: Router, private toastrService: ToastrService) {}

  orderArray?: Order[];
  summaries?: OrdersSummary;
  

  ngOnInit() {
    this.fetchPageData();
  }

  /**
   * Description
   * @param {string} orderExtId
   * @param {ProductsOrder} currentProduct
   * @param {boolean} allowed
   * @returns {void}
   */
  clickDownloadAll(orderExtId: string, currentProduct: ProductsOrder, allowed: boolean = true): void {
    if (!allowed) {
      return;
    }

    var a = document.createElement("a"); //Create <a>
  
    a.href = currentProduct.img1?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_1`; //File name Here
    a.click();

    a.href = currentProduct.img2?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_2`; //File name Here
    a.click();

    a.href = currentProduct.img3?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_3`; //File name Here
    a.click();

    a.href = currentProduct.img4?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_4`; //File name Here
    a.click();

    a.href = currentProduct.img5?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_5`; //File name Here
    a.click();

    a.href = currentProduct.img6?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_6`; //File name Here
    a.click();

    a.href = currentProduct.img7?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_7`; //File name Here
    a.click();

    a.href = currentProduct.img8?.data!;
    a.download = `${orderExtId}_${currentProduct.id}_8`; //File name Here
    a.click();
  }


  /**
   * Description
   * @param {'order-details' | 'user-details' | 'message-user'} type
   * @param {Order | User} handleObject
   * @returns {void}
   */
  clickRedirect(type: 'order-details' | 'user-details' | 'message-user', handleObject: Order | User ): void {
    switch (type) {
      case 'order-details':
        this.router.navigateByUrl(`/auth/orders/details/${handleObject.id}`)
        break;
    
      default:
        break;
    }
  }

  /**
   * Description
   * @param {Order} order
   * @returns {void}
   */
  clickStatus(currentOrder: Order, statusCode: string): void {
    const order = JSON.parse(JSON.stringify(currentOrder));
    if (!order.orderStatus) {
      order.orderStatus = { id: null, code: null, name: null } as unknown as OrderStatus;
    }

    order.orderStatus = { id: null, code: statusCode, name: null } as unknown as OrderStatus;

    this.ordersService.updateOrderStatus(order)
      .subscribe((orderRes: Response<Order>) => {
        if (orderRes.operationSuccess) {
          this.fetchPageData();
          return;
        }

        orderRes.messages.forEach((currentMessage: ResponseMessage) => {
          this.toastrService.error(currentMessage.message);
        })
      })
  }

  /**
   * Description
   * @returns {void}
   */
  fetchPageData(): void {
    this.ordersService.getActiveOrders()
      .subscribe((orderRes: InquiryResponse<Order>) => {
        console.log('this.ordersService.getOrders() => orderRes', orderRes);
        this.orderArray = orderRes.returnValues;
      });


    // [TODO]change summaries to be monthly
    this.ordersService.getOrdersSummary()
      .subscribe((summaryRes: Response<OrdersSummary>) => {
        console.log('this.ordersService.getOrdersSummary() => summaryRes', summaryRes);
        if (summaryRes.isInError || !summaryRes.operationSuccess) {
          summaryRes.messages.forEach((currentMessage: ResponseMessage) => {
            this.toastrService.error(currentMessage.message);
          })
          return;
        }
        this.summaries = summaryRes.item;
      })  
  }
}
