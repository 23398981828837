import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Order } from '../../../app/models/Order';
import { InquiryResponse } from '../../../app/models';
import { Response } from '../../../app/models';
import { OrdersSummary } from '../../../interactionModels/OrdersSummary';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private httpClient: HttpClient){}

  /**
   * Description
   * @returns {Observable<InquiryResponse<Order>>}
   */
  getActiveOrders(): Observable<InquiryResponse<Order>> {
    return this.httpClient.get<InquiryResponse<Order>>(`${environment.apiUrl}order/active`);
  }

  /**
   * Description
   * @returns {Observable<Response<OrdersSummary>>}
   */
  getOrdersSummary(): Observable<Response<OrdersSummary>> {
    return this.httpClient.get<Response<OrdersSummary>>(`${environment.apiUrl}order/summary`);
  }

  /**
   * Description
   * @returns {Observable<Response<Order>>}
   */
  updateOrderStatus(order: Order): Observable<Response<Order>> {
    return this.httpClient.put<Response<Order>>(`${environment.apiUrl}order/orderStatus`, order);
  }
}
