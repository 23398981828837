import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Datatable, DatatableSettingsItemType, Event } from '../models';

import db from 'just-debounce';

@Component({
  selector: 'elmtr-datatable',
  templateUrl: './datatable.component.html',
  styleUrl: './datatable.component.scss'
})
export class DatatableComponent {
  @Input() settings!: Datatable;
  @Input() data!: Array<any>;

  @Output() tableClicked = new EventEmitter()

  debounceFunction: any;
  itemType = DatatableSettingsItemType;
  tableSettings: any;
  tableControlls = {
    empty: true
  };

  constructor() { }

  ngOnInit(): void {
    // console.log('ngOnInit', this.settings);

    this.startDebounce();
  }

  ngOnChanges(): void {
    if (this.data && this.data.length > 0) {
      this.tableSettings = this.settings?.parseArray(this.data);
      this.tableControlls.empty = false;
    } else {
      this.tableControlls.empty = true;
    }
  }

  itemClicked(column: any): void {
     this.tableClicked.emit(new Event('columnClicked', {
      originalRow: column.row.originalRow,
      rowSettings: column.settings,
      columnClicked: column.column
    }));
  }

  startDebounce(): void {
    this.debounceFunction = db((changeStateEmitter: any, emittParam: any) => {
      changeStateEmitter.emit(emittParam);
    }, 500);
  }



  shortText(textObject: any): string {
    let newString = ``;
    for (let i = 0; i < textObject.size; i++) {
      const element = textObject.text[i];
      newString = `${newString}${element}`;
    }

    return `${newString}...`;
  }

}
