<div class="callout callout-info"  *ngIf="this.tableControlls.empty && this.settings">
  Não há informações para serem exibidas aqui.
</div>

<div class="card"  *ngIf="!this.tableControlls.empty && this.settings">
    <div class="card-body table-responsive p-0">
      <table class="table table-hover text-nowrap">
        <thead class="table-header">
          <tr>
            <!-- {{ this.settings | json }} -->
            <th *ngFor="let column of this.settings.settingsArray" class="table-header-item">{{ column.label }}</th>

          </tr>
        </thead>
        <tbody> 
          <!-- {{ this.tableSettings | json }} -->
          <tr *ngFor="let row of this.tableSettings">
            <!-- {{row.parsedRow | json}} -->
            <td 
              *ngFor="let column of row.parsedRow; let i = index" 
              (click)="this.itemClicked({ column: column, settings: this.settings.settingsArray![i], row: row })"
            >
              <!-- {{ this.settings.settingsArray![i].type | json }} -->
              <p *ngIf="this.settings.settingsArray![i].type === this.itemType.Text">
                <span *ngIf="column.value">{{ column.value }}</span>
                <span *ngIf="!column.value"> -- </span>
              </p>
              
              <p *ngIf="this.settings.settingsArray![i].type === this.itemType.TextCustom">
                <span 
                  *ngIf="column.value.text"
                  [ngClass]="column.value.ngClass ? column.value.ngClass : null"
                  [ngStyle]="column.value.ngStyle ? column.value.ngStyle : null"
                >
                  {{ (column.value.size && (column.value.text.length > column.value.size))  ? shortText(column.value) : column.value.text }}
                </span>
                <span *ngIf="!column.value.text"> -- </span>
              </p>
              
              <p *ngIf="this.settings.settingsArray![i].type === this.itemType.Link">
                {{ column.value }}
              </p>

              <p *ngIf="this.settings.settingsArray![i].type === this.itemType.Date">
                {{ column.value }}
              </p>

              <!-- {{ this.settings.settingsArray![i].type === this.itemType.Icon ? column.value.icon : '' | json }} -->
              <i 
                *ngIf="this.settings.settingsArray![i].type === this.itemType.Icon" 
                [class]="'fas ' + column.value.icon" 
                [ngClass]="column.value.ngClass ? column.value.ngClass : null"
                [ngStyle]="column.value.ngStyle ? column.value.ngStyle : null"
              ></i>

              <a 
                *ngIf="this.settings.settingsArray![i].type === this.itemType.Email" 
                [href]="'mailto:'+ column.value" 
                data-toggle="tooltip" 
                data-placement="top" 
                [title]="column.value"
              >
                <i  class="fas fa-envelope"></i>
              </a>

              <div
                *ngIf="this.settings.settingsArray![i].type === this.itemType.Image"  
                class="table-image-wrapper"
              >
                <img 
                  [src]="column.value.data"
                  [ngClass]="column.value.ngClass ? column.value.ngClass : null"
                  [ngStyle]="column.value.ngStyle ? column.value.ngStyle : null" 
                  alt="" 
                  srcset=""
                >
              </div>
              <!-- <i *ngSwitchCase="this.itemType.Icon" [class]="column.value.icon + ' fas '"></i> -->

              <!-- <span *ngSwitchCase="this.itemType.Icon" [class]="'fas ' + column.value.icon"></span> -->
              <!-- <p *ngSwitchDefault>
                {{ column | json }}
              </p> -->
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- /.card-body -->
  </div>
