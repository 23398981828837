import { Component } from '@angular/core';

import { environment } from "../../../environments/environment";

@Component({
  selector: 'elmtr-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent {

  brandName: string | null = null;
  brandLogo: string | null = null;

  rotatorCurrentItem: { brandName: string, brandLogo: string } | null = null;

  ngOnInit(): void {
    this.brandLogo = localStorage.getItem(environment.localStorage.franchise.brand);

    this.animateFooter();
  }

  animateFooter():  void {
    const brandList: { brandName: string, brandLogo: string }[] = [
      {
        brandName: 'Elementar',
        brandLogo: '../../../assets/images/ELEMENTAR_STANDARD_LOGO.png'
      },
    ];
    let brandListIndex = 0;

    if (this.brandLogo) {
      brandList.push({
        brandName: 'Fotons',
        brandLogo: '../../../assets/images/NO_BG_BANNER_LOGO_LIGHT.png'
      });
    }

    // console.log('brandList', brandList);


    this.rotatorCurrentItem = brandList[0];
    // setInterval(() => {
    //   this.rotatorCurrentItem = brandList[brandListIndex];
    //   brandListIndex ++;

    //   if (brandListIndex === brandList.length) {
    //     brandListIndex = 0;
    //   }
    // }, 10000);
  }
}
