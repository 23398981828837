import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ProductsComponent } from './products/products.component';
import { ProductDetailsComponent } from './product-details/product-details.component';
import { ElementarCommonModule } from '../elementar-common/elementar-common.module';
import { OrdersComponent } from './orders/orders.component';
import { ClientsComponent } from './clients/clients.component';



@NgModule({
  declarations: [
    ProductsComponent,
    ProductDetailsComponent,
    OrdersComponent,
    ClientsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ElementarCommonModule
  ],
  exports: [
    ProductsComponent,
    ProductDetailsComponent,
    OrdersComponent,
    ClientsComponent
  ]
})
export class FotonsModule { }
