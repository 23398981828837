import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'elmtr-authenticated',
  templateUrl: './authenticated.component.html',
  styleUrl: './authenticated.component.scss'
})
export class AuthenticatedComponent implements OnInit {

  ngOnInit(): void {
    console.log('AuthenticatedComponent');
  }
}
