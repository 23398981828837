import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { Order } from '../../../app/models/Order';
import { InquiryResponse, Product, ProductsOrder, Response } from '../../../app/models';
import { OrdersSummary } from '../../../interactionModels/OrdersSummary';


@Injectable({
  providedIn: 'root'
})
export class ProductDetailsService {

  constructor(private httpClient: HttpClient){}

  /**
   * Description
   * @param {any} code:string
   * @returns {any}
   */
  fetchProductByCode(code: string): Observable<Response<Product>> {
    return this.httpClient.get<Response<Product>>(`${environment.apiUrl}products/byCode/${code}`);
  }

  /**
   * Description
   * @param {any} code:string
   * @returns {any}
   */
  fetchLiveOrdersByProductCode(code: string): Observable<InquiryResponse<Order>> {
    return this.httpClient.get<InquiryResponse<Order>>(`${environment.apiUrl}order/live/byProductCode/${code}`);
  }

  /**
   * Description
   * @param {any} code:string
   * @returns {any}
   */
  fetchProductsOnOrdersByCode(code: string): Observable<InquiryResponse<ProductsOrder>> {
    return this.httpClient.get<InquiryResponse<ProductsOrder>>(`${environment.apiUrl}products/productsOrder/byProductCode/${code}`);
  }

  
}
