import { DatatableSettingsItem } from './DatatableSettingsItem';
import { DatatableSettingsItemType } from './DatatableSettingsItemType';

export class Datatable {
    settingsArray: DatatableSettingsItem[] | null = [];

    tableData: any[] = [];

    constructor(settingsArray: DatatableSettingsItem[] | null = null){
      this.settingsArray = settingsArray;
    }


    public parseArray(originalArray: Array<any>): any {
        const finalArray: any[]  = [];

        originalArray.forEach(row => {
            let finalRow: any[] = [];

            this.settingsArray?.forEach(setting => {
                const currentRow = this.findSetting(setting, row);
                if (currentRow) {
                    switch (setting.type) {
                        case DatatableSettingsItemType.Text:
                            finalRow.push({
                                field: setting.field,
                                value: currentRow[setting.field]
                            });
                            break;

                        default:
                            finalRow.push({
                                field: setting.field,
                                value: currentRow[setting.field]
                            });
                            break;
                    }
                }
            });
            finalArray.push({ parsedRow: finalRow, originalRow: row });
        });
        return finalArray;
    }

    findSetting(setting: DatatableSettingsItem, row: any): any {
        let itemFiding = null;
        Object.keys(row).forEach(column => {
            if (setting.field === column) {
               itemFiding = row;
            }
        });
        return itemFiding;
    }
  }
