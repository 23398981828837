import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AuthenticatedComponent } from "./layouts/authenticated/authenticated.component";
import { PublicComponent } from "./layouts/public/public.component";
import { SideMenuComponent } from "./side-menu/side-menu.component";
import { RouterOutlet } from '@angular/router';
import { DatatableComponent } from "./datatable/datatable.component";
import { TestComponent } from './test/test.component';
import { DatesPipe } from './pipes/dates.pipe';
import { UserCardComponent } from './user-card/user-card.component';


@NgModule({
  declarations: [
    HeaderComponent,
    AuthenticatedComponent,
    PublicComponent,
    SideMenuComponent,
    DatatableComponent,
    TestComponent,
    DatesPipe,
    UserCardComponent
  ],
  imports: [
    CommonModule,
    RouterOutlet
  ],
  exports: [
    HeaderComponent,
    AuthenticatedComponent,
    PublicComponent,
    SideMenuComponent,
    DatatableComponent,
    TestComponent,
    DatesPipe,
    UserCardComponent
  ]
})
export class ElementarCommonModule { }
