import { Component } from '@angular/core';
import { Datatable, DatatableSettingsItem, DatatableSettingsItemType } from '../models';

@Component({
  selector: 'elmtr-test',
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {
  datatable = new Datatable([
    new DatatableSettingsItem('temp',DatatableSettingsItemType.Text, 'Temp1'),
    new DatatableSettingsItem('temp1',DatatableSettingsItemType.Text, 'Temp2'),
    new DatatableSettingsItem('temp2',DatatableSettingsItemType.Text, 'Temp3'),
  ]);
}
